import React, { useState, useEffect, useRef } from "react";
import { models } from "powerbi-client";
import "./MVDWarroom.css";
import { useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useDocumentTitle from "../hooks/useDocumentTitle";
import EvaButton from "../EvaButton/EvaButton";
import { axiosInstance } from "../libs/axios/axiosInstance";

const MVDWarroom = () => {
  const embedRef = useRef(null);
  const context = useOutletContext();
  useDocumentTitle(context.dashboard_name);
  const user = useSelector((state) => state.auth.user);

  const getAccessToken = async () => {
    const url = "powerbi/get-access-token";
    try {
      const response = await axiosInstance.post(url, {}, {
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      if(response.status === 200){
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  };

  const getTokenPowerBI = async (accessToken) => {
    const requestBody = JSON.stringify({
      "accessLevel": "View",
      "identities": [
        {
          "datasets": [context.dataset_id],
          "roles": context.pbi_access,
          "username": user.user.email,
        },
      ],
      "datasets": [
        { "id": context.dataset_id },
      ],
      "reports": [
        {
          "allowEdit": false,
          "id": context.report_id,
        },
      ],
      "lifetimeInMinutes": 50,
    });

    const response = await fetch('https://api.powerbi.com/v1.0/myorg/GenerateToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: requestBody,
    });
    const data = await response.json();
    return data;
  }

  const embedPowerBIReport = async () => {
    try {
      const token = await getAccessToken();
      const tokenPowerBI = await getTokenPowerBI(token.access_token);
      const embedConfig = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: tokenPowerBI.token,
        embedUrl: 'https://app.powerbi.com/reportEmbed',
        id: context.report_id,
        settings: {
          filterPaneEnabled: context.is_filter,
          navContentPaneEnabled: context.is_panel,
        },
      };

      window.powerbi.embed(embedRef.current, embedConfig);
    } catch (error) {
      console.error('Failed render powerbi report:', error);
    }
  };

  const updateTokenPowerBI = async () => {
    try {
      const token = await getAccessToken();
      const tokenPowerBI = await getTokenPowerBI(token.access_token);
      let embedPowerBI = window.powerbi.get(embedRef.current);
      await embedPowerBI.setAccessToken(tokenPowerBI.token);
    } catch (error) {
      console.error("Failed Update token powerBI");
    }
  }


  useEffect(() => {
    if (user) {
      embedPowerBIReport();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTokenPowerBI();
    }, 15 * 60 * 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="App" style={{ position: "relative" }}>
      <EvaButton />
      <div className="" ref={embedRef} style={{ height: '100vh', width: '100%', borderWidth: '0' }} />
    </div>
  );
};

export default MVDWarroom;
