import React from "react";
import "./EvaDashboard.css";
import Eva from "../assets/eva.jpeg";
import Dash from "../assets/dash.svg";
import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../libs/redux/slices/sidebarSlices';

const EvaDashboard = () => {
  const dispatch = useDispatch();

  return (
    <div className="eva-dashboard">
      <div className="overlap-group">
        <img className="ellipse" alt="Ellipse" src={Eva} />
        <div className="text-wrapper">Eva</div>
      </div>
      <img
        src={Dash}
        alt=""
        className="cursor-pointer"
        onClick={() => dispatch(toggleSidebar())}
      />
    </div>
  );
};

// Memoize the Bot component
// const MemoizedBot = React.memo(Bot);

export default EvaDashboard;
