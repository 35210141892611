import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosAuth from '../../axios/axiosAuth';

export const me = createAsyncThunk('auth/me', async (_, thunkAPI) => {
  try {
    const URL = 'auth/get-dashboard-warroom';
    const response = await axiosAuth.get(URL, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    if (response.status === 200) {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      new Error(response.statusText)
    }
  } catch (error) {
    console.error("Failed to fetch user data", error)
    return thunkAPI.rejectWithValue("Invalid Credentials")
  }
})

const initialState = {
  user: {},
  isUserLoggedIn: null,
  isLoading: true,
  isSessionExpired: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoading = true;
    },
    logout: (state) => {
      state.user = {};
      state.isUserLoggedIn = null;
      state.isLoading = true;
    },
    sessionExpired: (state) => {
      state.isSessionExpired = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(me.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(me.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isUserLoggedIn = true;
      state.isLoading = false;
    });
    builder.addCase(me.rejected, (state) => {
      state.isUserLoggedIn = null;
      state.isLoading = false;
    });
  }
});

export const { login, logout, sessionExpired } = authSlice.actions;
export default authSlice.reducer;
