import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { me, sessionExpired } from "../libs/redux/slices/authSlice";
import { useEffect } from "react";
import LoadingPage from "../LoadingPage";
import { setSessionExpiredHandler } from "../libs/axios/axiosInstance";

const PrivateRoutes = () => {
  const dispatch = useDispatch()
  const { isLoading, isUserLoggedIn } = useSelector((state) => state.auth)

  useEffect(() => {
    setSessionExpiredHandler(() => dispatch(sessionExpired()))
    if (isUserLoggedIn === null) {
      dispatch(me());
    }
  }, [isUserLoggedIn, dispatch]);

  if (isLoading) return <LoadingPage />

  return (
    isUserLoggedIn ? <Outlet /> : <Navigate to={'/login'} />
  );
}

export default PrivateRoutes