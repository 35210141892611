import { Outlet, useParams } from "react-router-dom";
import NotFoundPage from "../NotFoundPage";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const DashboardValidator = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    // const listDashboard = {
    //     'mvd-production': {
    //         dataset_id: '72d33eb1-9e25-4669-a8fb-3925e5f40e09',
    //         report_id: '6ec4cc08-c706-4f89-8431-1e03bad8ae1c',
    //         content_panel_enabled : false,
    //         filter_panel_enabled : true,
    //         role: user.role.production?.name,
    //         title: 'MVD Production'
    //     },
    //     'mvd-investor': {
    //         dataset_id: 'd3276cdb-f550-422e-a424-578430e305ae',
    //         report_id: '899c2c7b-b30a-4b63-a1eb-d9381b21eb59',
    //         content_panel_enabled : false,
    //         filter_panel_enabled : true,
    //         role: user.role.investor?.name,
    //         title: 'MVD Investor'
    //     },
    // }
    const params = useParams('dashboard_id')
    const dashboard = user.dashboard.find(dashboard => dashboard.slug === params.dashboard_id);
    return dashboard ? <Outlet context={dashboard} /> : <NotFoundPage />
}
export default DashboardValidator;