import React, { useState, useEffect } from 'react';
import SinarmasImage from "../assets/sinarmas.png"
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import "./LoginPage.css"
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { login } from '../libs/redux/slices/authSlice';
import useDocumentTitle from '../hooks/useDocumentTitle';
import LoadingPage from '../LoadingPage';
import axiosAuth from '../libs/axios/axiosAuth';

const SSO_INACTIVE_USER = "INACTIVE_USER"
const SSO_NOT_REGISTERED = "NOT_REGISTERED"
const SSO_LOGIN_FAILED = "LOGIN_FAILED"
const SSO_NOT_AUTHENTICATED = "NOT_AUTHENTICATED"

const LoginError = ({ title, message }) => {
  return <div className="login-error login-container">
    <div className='card'>
      <img src={SinarmasImage} alt="Sinarmas Logo" className="logo" />
      <h1>{title}</h1>
      <div className="message">
        {message}
      </div>
      <Link to='/login' className='btn-primary'>Back to Login</Link>
    </div>
  </div>
}

const LoginPage = () => {
  useDocumentTitle('Login');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  if (queryParams.get("msg")) {
    let encodedMsg = queryParams.get("msg")
    // const paddingNeeded = 4 - (encodedMsg.length % 4)
    // if (paddingNeeded) {
    //   encodedMsg += "=".repeat(paddingNeeded)
    // }
    const decodedMsg = atob(encodedMsg)
    if (decodedMsg === SSO_NOT_REGISTERED) {
      return <LoginError title={"Account Not Registered"} message="Unfortunately! It seems that your account has not been registered yet by the administrator. If you need assistance with registering your account, please contact our support teamA" />
    } else if (decodedMsg === SSO_LOGIN_FAILED) {
      return <LoginError title={"Login Failed"} message="Unfortunately! It seems that your account status has been set to inactive by the administrator. If you need assistance with re-activating your account, please contact our support team" />
    } else if (decodedMsg === SSO_NOT_AUTHENTICATED) {
      return <LoginError title={"Login Failed"} message="Unfortunately! It seems that your account status has been set to inactive by the administrator. If you need assistance with re-activating your account, please contact our support team" />
    } else if (decodedMsg === SSO_INACTIVE_USER) {
      return <LoginError title={"Account Inactive"} message="Unfortunately! It seems that your account status has been set to inactive by the administrator. If you need assistance with re-activating your account, please contact our support team" />
    }
  }

  // uncomment once sso is ready

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const queryToken = urlParams.get('token'); 
  //   const queryNik = urlParams.get('nik'); 

  //   if (queryToken && queryNik) {
  //     sessionStorage.setItem('nik', queryNik);
  //     navigate('/home');
  //   }
  // }, [navigate]);

  // const handleLogin = () => {
  //   //change to sinarmas sso url
  //   const ssoUrl = `http://localhost:3001?nik=${nik}`;

  //   window.location.href = ssoUrl;
  // };


  const handleClaim = async (claim) => {
    // const URL = "http://localhost:8000/auth/claim";
    const URL = "https://genai-api.nawatech.co/auth/claim";
    try {
      const response = await axios.post(URL, { claim_token: claim })
      if (response.status === 200) {
        const result = response.data;
        localStorage.setItem('access_token', result.data.access_token);
        navigate('/home')
      }
    } catch (error) {
      console.error('failed to claim', error)
      navigate('/login')
    }
  }

  if (queryParams.get('claim')) {
    const claim = queryParams.get('claim');
    handleClaim(claim);
    return <LoadingPage />;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const URL = "api/users/login";
      // const URL = "http://localhost:8000/api/users/login";
      const response = await axiosAuth.post(URL, {
        nik: username,
        password: password
      }, {
        headers: {
          'x-api-key': 'e1a38197a2c0fa11176188725b6e206dc824203f',
        },
      })
      if (response.status === 200) {
        const result = response.data;
        localStorage.setItem('access_token', result.data.access_token);
        setIsLoading(false);
        dispatch(login());
        navigate('/home')
      }
    } catch (error) {
      console.error('failed to fetch', error)
      if (error.response?.status === 401) {
        setErrorMessage('Invalid nik or password');
      }
      setIsLoading(false);
    };
  };

  const handleLoginWithSSO = () => {
    const ORIGIN = window.origin;
    window.location.href = `https://genai-api.nawatech.co/auth/login?relay_state=${ORIGIN}/login`;
    // window.location.href = `http://localhost:8000/auth/login?relay_state=${ORIGIN}/login?asdasdsad=asdasd`;
  }

  return (
    <div className="login-container">
      <img src={SinarmasImage} alt="Sinarmas Logo" className="logo" />
      <div className="disclaimer">
        Please do not put in your real password, this is just for testing purposes.
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form onSubmit={handleLogin}>
        <div className="login-form">
          <input
            type="text"
            placeholder="Enter NIK"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required={true}
          />
          <input
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required={true}
          />
          <button type='submit' disabled={isLoading}>Login</button>
        </div>
      </form>
      <button onClick={handleLoginWithSSO} className='btn-style-reset' disabled={isLoading}>Login With SSO</button>
    </div>
  );
};

export default LoginPage;