import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from "./LoginPage/LoginPage"
import Homepage from "./Homepage/Homepage";
import MVDWarroom from './MVDWarroom/MVDWarroom';
import "./App.css";
import PrivateRoutes from './auth/PrivateRoutes';
import TestPrivateRoutes from './auth/TestPrivateRoutes';
import TestHomePage from './auth/TestHomePage';
import DashboardValidator from './auth/DashboardValidator';
import NotFoundPage from './NotFoundPage';
import MainLayout from './components/MainLayout';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={'/home'} />} />
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoutes />}>
          <Route element={<MainLayout />}>
            <Route path="/home" element={<Homepage />} />
            <Route element={<DashboardValidator />}>
              <Route path="/war-room/:dashboard_id" element={<MVDWarroom />} />
            </Route>
          </Route>
3        </Route>
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Routes>
    </Router>
  );
}

export default App;