import React, { useState } from "react";
import "./EvaButton.css";
import Eva from "../assets/eva.jpeg";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import WebChatComponent from "../Bot";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../libs/redux/slices/sidebarSlices";

const EvaButton = () => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);
  const [isMove, setIsMove] = useState(false);
  const onClickMoveable = () => {
    if (isMove) {
      setIsMove(false)
    } else {
      setIsMove(true)
    }
  }
  return (
    <>
      <Draggable disabled={!isMove} defaultPosition={{ x: 0, y: 10 }}>
        <div className={isMove ? 'moveable' : ''}>
          <button className="eva-btn" onClick={() => dispatch(toggleSidebar())} disabled={isMove}>
            <div className="eva-btn-text">EVA</div>
          </button>
          <img className="eva-btn-image" alt="EVA" src={Eva} onDoubleClick={onClickMoveable} />
        </div>
      </Draggable>
      <div className={`sliding-div ${isSidebarOpen ? "visible" : ""}`}>
        <WebChatComponent />
      </div>
    </>

  );
};

export default EvaButton;