import { Outlet } from "react-router-dom"
import SessionExpiredModal from "./SessionExpiredModal"
import ToastsRedux from "./Toasts"


const MainLayout = () => {
    return (
        <>
            <SessionExpiredModal />
            <ToastsRedux />
            <div>
                <Outlet />
            </div>
        </>
    )
}

export default MainLayout